import { post, get, destroy, download, API_ENDPOINT, getPappiga } from '@/helpers/api'

export default {
    ROUTE_NAME (context, routeName) {
        context.commit('SET_CURRENT_ROUTE', routeName)
    },

    LOGIN (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'auth/user-login', credentials) 
                .then(response => {
                    context.commit('SET_USER', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
  
    LOGOUT (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'auth/logout')
            .then(response => {
                context.commit('SET_USER', null)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    PASSWORD_RESET_REQUEST (context, email) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/create', email)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/reset', credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_TOKEN_FIND(context, token) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'password/find/' + token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    RESTRICTION_TYPES (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/restriction-types')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },


    
    CREATE_RFID_USER (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/rfid-users/create', data )
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_RFID_USER (context, userId) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'vrata/rfid-users/' + userId)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_RFID_USER(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/rfid-users/' + data.user_uuid + '/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_RFID_USER (context, id) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'vrata/rfid-users/' + id + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARAMETERS_DATA (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/parameters-data')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_SETTINGS(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/settings')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    SAVE_SETTINGS(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/settings/save', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PUBLISH (context, payload) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'vrata/mqtt-publish', payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    SYNC_USER(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/rfid-users/'+ uuid + '/sync')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CONVERT_RFID_NR (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'utilities/rfid-nr/6', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RFID_NR_SEARCH (context, nr) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'utilities/rfid-nr/6/' + nr)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARKING_GATES (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'vrata/parking-gates/devices')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARKING_GATES_PARAMETERS_DATA (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'vrata/parking-gates/parameters-data')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_PARKING_DATA(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/parking-occupancy/list')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_PARKING_OCCUPANCY(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/parking-occupancy/' + data.uuid + "/update", data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    STORE_OR_UPDATE_TICKET (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/tickets/store-or-update', data )
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ADD_REGISTRATION_NUMBER(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/registration-numbers/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_REGISTRATION_NUMBER(context, id) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'vrata/registration-numbers/'+ id + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    SYNC_REGISTRATION_NUMBER(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/registration-numbers/'+ uuid + '/sync')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DOWNLOAD_FILE(context, uuid) {
        return new Promise( (resolve, reject) => {
            download(API_ENDPOINT + 'vrata/exports/'+ uuid + '/download')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_FILE(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'vrata/exports/'+ uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    EXPORT(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/export/' + data.group, data.queryParameters)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_RFID_USER_CARD_DATA (context, userId) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/rfid-users/' + userId + '/card-data')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CREATE_PARKING_GATE_TIMELINE (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'services/parking-gates/timelines/create', data )
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_PARKING_GATE_TIMELINE (context, id) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'sesrvices/parking-gates/timelines/' + id + '/view')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_PARKING_GATE_TIMELINE(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'services/parking-gates/timelines/' + data.uuid + '/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },


    SYNC_PARKING_GATE_TIMELINE(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'services/parking-gates/timelines/'+ uuid + '/sync')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    DELETE_PARKING_GATE_TIMELINE (context, id) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'services/parking-gates/timelines/' + id + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DEVICE_STATUS(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'services/parking-gates/status', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DATE_TIME_DURATION(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'services/date-time/duration', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    REGISTRATION_NUMBER_VALIDATION(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'vrata/registration-numbers/price', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARKING_OCCUPANCY_DATA(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'services/parking-occupancy', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARKING_OCCUPANCY_ITEM_DATA(context, avtId) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'services/parking-occupancy/avt/' + avtId)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARKING_OCCUPANCY_UPDATE(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'services/parking-occupancy/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
    
}